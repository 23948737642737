import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

// markup
const IndexPage = () => {
   return (
      <main>
         <div className='min-h-screen flex flex-col justify-center items-center'>
            <h1 className='text-cornflower-blue text-center text-3xl pt-10 font-bold'>
               KEYSPECS
            </h1>
            <section className='text-center mx-6 lg:w-2/3'>
               <div className='pt-2'>
                  <StaticImage
                     className='m-auto w-48 lg:w-64'
                     src='../images/logo.png'
                     alt='Keyspecs Logo'
                  />
               </div>

               <div className='flex-col flex pt-24 sm:text-md md:text-xl text-gray-800'>
                  <p className='text-xl'>Hi there 👋,</p>
                  <p className='pt-4'>
                     You've probably found us because of some of the projects
                     we've been working hard on delivering.
                  </p>
                  <p className='pt-2'>
                     At Keyspecs we aim to ensure that your key specifications
                     are always identified and realized.
                  </p>
                  <p>
                     We’d love to hear from you! To get in touch, send us a
                     message using the email below:
                  </p>
                  <p className='text-cornflower-blue pt-2'>apps@keyspecs.com</p>
                  <p className='pt-6'>- Keyspecs Team</p>
               </div>
            </section>
         </div>
      </main>
   );
};

export default IndexPage;
